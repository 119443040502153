@media (max-width: 600px) {
    .imagen1 {
        width: 90vw;
        height: 90%;
        top:0;
    }
    .text-btn{
        padding-left: 50px;
    }
}

.imagen1{
    width: auto1;
    height: auto;
    top:100px;
    position:relative; 
   
}
.text-btn{
    padding-left: 20vw;
}
.btn-1{
    margin: 0px 30px !important;
    padding: 0px 10px;
}
.btn-2{
    margin: 0px 30px !important;
    padding: 0px 10px;
}

.circle{
    background-color: #6622EE;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.mrg1{
    margin: 20px 0px ;
}

.mrg2{
    margin: 15px 0px;
}

.mrg3{
    margin: 80px 0px ;
}