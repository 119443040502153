* { box-sizing: border-box; }
body { margin: 0; }

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 500px;
  padding: 4%;
  padding-bottom: 5%;
}

.items{
    /* flex: 0 1 50%;   */
}

.paymentFooter {
  padding-top: 6%;
  flex: 0 1 8%;
}

.shipping {
    flex: 0 1 auto;
}